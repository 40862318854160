import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import classnames from 'classnames';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';

import Button from 'components/Button';
import { resolveSlice } from 'utils/slice-resolver.js';
import useSiteMetadata from 'hooks/useSiteMetadata';

import css from './press.module.scss';
import shared from 'styles/shared.module.scss';

const getImageRows = images => {
  const chunkSize = 2;
  const rows = [];
  for (let i = 0; i < images.length; i += chunkSize) {
    rows.push(images.slice(i, i + chunkSize));
  }

  return rows;
};

const Press = ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const content = prismic.allPresss.edges[0];
  if (!content) return null;

  const press = content.node;
  const slices = press.body || [];

  const images = press.images || [];
  const imageRows = getImageRows(images);
  return (
    <>
      <Helmet>
        <title>{`SwissDeCode - Press`}</title>
        <meta description="Find the latest press releases and press materials from SwissDeCode" />
        <meta property="og:title" content="SwissDeCode - Press" />
        <meta
          property="og:description"
          content="Find the latest press releases and press materials from SwissDeCode"
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta
          property="og:image"
          content="https://images.prismic.io/swissdecode/1bf48c1c-287f-4e1d-a01e-57a60c0a5f5f_press+releases.jpg?auto=compress,format"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SwissDeCode - Press" />
        <meta
          property="twitter:description"
          content="Find the latest press releases and press materials from SwissDeCode"
        />
        <meta name="twitter:creator" content="@swissdecode" />
        <meta
          name="twitter:image"
          content="https://images.prismic.io/swissdecode/1bf48c1c-287f-4e1d-a01e-57a60c0a5f5f_press+releases.jpg?auto=compress,format"
        />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <section className={classnames(css.host, shared.maxwidth)}>
        <h4 className={shared.sectionType}>{RichText.asText(press.label)}</h4>
        <h2 className={shared.sectionTitle}>{RichText.asText(press.title)}</h2>
        <div className={classnames(css.description, shared.sectionDescription)}>
          <RichText render={press.description} />
        </div>
        <div className={css.imageGrid}>
          {imageRows.map((row, rowIdx) => (
            <div className={css.imageRow} key={rowIdx}>
              {row.map(({ imageSharp }, idx) => (
                <div
                  key={idx}
                  className={classnames(css.imageContainer, {
                    [css.borderImage]: idx === 0,
                    [css.greyImage]: idx === 1,
                  })}
                >
                  <Img
                    fixed={imageSharp.childImageSharp.fixed}
                    className={css.image}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <a
          id="press-releases"
          href={press.download_link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button filled>{press.download_label}</Button>
        </a>
      </section>
      {slices.map(resolveSlice)}
    </>
  );
};

export default Press;

export const pageQuery = graphql`
  query PressByUid {
    prismic {
      allPresss {
        edges {
          node {
            _meta {
              uid
            }
            title
            label
            images {
              image
              imageSharp {
                childImageSharp {
                  fixed(quality: 90, width: 260) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
            description
            download_label
            download_link {
              ... on PRISMIC__FileLink {
                url
              }
            }
            body {
              ... on PRISMIC_PressBodyContent_section {
                type
                label
                primary {
                  alignment
                  title
                  type
                }
                fields {
                  content_text
                  icon
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link {
                    ... on PRISMIC__FileLink {
                      url
                    }
                  }
                  link_label
                  main
                }
              }
            }
          }
        }
      }
    }
  }
`;
